<template>
  <v-container fill-height>
    <v-layout justify-center>
      <v-flex xs12 sm12 md11 lg9 xl7>
        <v-form class="elevation-5">
          <template>
            <v-toolbar color="primary" dark dense>
              <v-icon class="mr-2">mdi-account-box</v-icon>
              <v-toolbar-title>{{ tituloForm }}</v-toolbar-title>
              <v-spacer></v-spacer>

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="mr-2"
                    v-bind="attrs"
                    v-on="on"
                    @click="metClear"
                  >
                    mdi-refresh
                  </v-icon>
                </template>
                <span>Refresh</span>
              </v-tooltip>

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="mr-2"
                    v-bind="attrs"
                    v-on="on"
                    @click="metVoltar"
                  >
                    mdi-arrow-left-circle-outline
                  </v-icon>
                </template>
                <span>Voltar</span>
              </v-tooltip>
            </v-toolbar>
          </template>

          <template>
            <v-container fluid>
              <v-row dense>
                <v-col cols="auto" md="2" align-self="center">
                  <v-text-field
                    dense
                    hide-details="auto"
                    v-model="varPFisicaLocal.id"
                    label="ID"
                    readonly
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="auto" md="10" align-self="center">
                  <v-text-field
                    dense
                    :error-messages="nomeErrors"
                    hide-details="auto"
                    v-model="varPFisicaLocal.nome"
                    label="Nome"
                    outlined
                    required
                    @input="$v.varPFisicaLocal.nome.$touch()"
                    @blur="$v.varPFisicaLocal.nome.$touch()"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="auto" md="3" align-self="center">
                  <v-text-field
                    dense
                    :error-messages="cpfErrors"
                    hide-details="auto"
                    v-model="varPFisicaLocal.cpf"
                    label="CPF"
                    v-mask="'###.###.###-##'"
                    outlined
                    required
                    @input="$v.varPFisicaLocal.cpf.$touch()"
                    @blur="$v.varPFisicaLocal.cpf.$touch()"
                  ></v-text-field>
                </v-col>
                <v-col cols="auto" md="3" align-self="center">
                  <v-text-field
                    dense
                    hide-details="auto"
                    v-model="varPFisicaLocal.rg"
                    label="RG"
                    outlined
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="auto" md="2" align-self="center">
                  <v-text-field
                    dense
                    hide-details="auto"
                    v-model="varPFisicaLocal.cep"
                    label="CEP"
                    v-mask="'#####-###'"
                    outlined
                    @blur="metObterDadosCep(varPFisicaLocal.cep)"
                  ></v-text-field>
                </v-col>
                <v-col cols="auto" md="8" align-self="center">
                  <v-text-field
                    dense
                    hide-details="auto"
                    v-model="varPFisicaLocal.endereco"
                    label="Endereço"
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="auto" md="2" align-self="center">
                  <v-text-field
                    dense
                    hide-details="auto"
                    v-model="varPFisicaLocal.numero"
                    label="Número"
                    outlined
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="auto" md="6" align-self="center">
                  <v-text-field
                    dense
                    hide-details="auto"
                    v-model="varPFisicaLocal.complemento"
                    label="Complemento"
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="auto" md="6" align-self="center">
                  <v-text-field
                    dense
                    hide-details="auto"
                    v-model="varPFisicaLocal.bairro"
                    label="Bairro"
                    outlined
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="auto" md="5" align-self="center">
                  <v-autocomplete
                    dense
                    hide-details="auto"
                    v-model="varPFisicaLocal.idPais"
                    label="País"
                    :items="compPaisesSelect"
                    item-text="nome_pt"
                    item-value="id"
                    outlined
                    clearable
                  ></v-autocomplete>
                </v-col>
                <v-col cols="auto" md="5" align-self="center">
                  <v-text-field
                    dense
                    hide-details="auto"
                    v-model="varPFisicaLocal.cidade"
                    label="Cidade"
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="auto" md="2" align-self="center">
                  <v-text-field
                    dense
                    hide-details="auto"
                    v-model="varPFisicaLocal.uf"
                    label="UF"
                    outlined
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="auto" md="6" align-self="center">
                  <v-text-field
                    dense
                    hide-details="auto"
                    v-model="varPFisicaLocal.email"
                    label="E-mail"
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="auto" md="6" align-self="center">
                  <v-text-field
                    dense
                    hide-details="auto"
                    v-model="varPFisicaLocal.telefone"
                    label="Telefone"
                    v-mask="metMaskFone(varPFisicaLocal.telefone)"
                    outlined
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="auto" md="12" align-self="center">
                  <v-textarea
                    dense
                    hide-details="auto"
                    v-model="varPFisicaLocal.observacoes"
                    label="Observações"
                    rows="2"
                    outlined
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-container>
          </template>

          <template>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  class="ml-4 mr-4 mb-4"
                  color="green darken-1"
                  v-bind="attrs"
                  v-on="on"
                  @click="submit"
                >
                  mdi-check-circle
                </v-icon>
              </template>
              <span>Salvar</span>
            </v-tooltip>

            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  class="mr-4 mb-4"
                  color="red darken-1"
                  v-bind="attrs"
                  v-on="on"
                  @click="metVoltar"
                >
                  mdi-backspace
                </v-icon>
              </template>
              <span>Cancelar</span>
            </v-tooltip>
          </template>
        </v-form>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import ValidaCPFCNPJMixin from "@/mixins/ValidaCPFCNPJMixin";
import DatasMixin from "@/mixins/DatasMixin";
import axios from "../_services/axios";

const { mapState, mapActions } = createNamespacedHelpers("finPFisicas");

export default {
  mixins: [validationMixin, DatasMixin, ValidaCPFCNPJMixin],

  data: () => ({
    name: "FinPFisicasForm",
    tituloForm: "",
    varPFisicaLocal: {},
  }),

  validations: {
    varPFisicaLocal: {
      nome: { required },
      cpf: {
        required,
        validCPF: function (value) {
          return this.mixValidaCpfCnpj(value);
        },
      },
    },
  },

  computed: {
    ...mapState(["staPFisicaSelect", "staPaisesSelect"]),

    compPaisesSelect() {
      return this.staPaisesSelect;
    },

    nomeErrors() {
      const errors = [];
      if (!this.$v.varPFisicaLocal.nome.$dirty) return errors;
      if (!this.$v.varPFisicaLocal.nome.required) {
        errors.push("Nome é obrigatório.");
      }
      return errors;
    },

    cpfErrors() {
      const errors = [];
      if (!this.$v.varPFisicaLocal.cpf.$dirty) return errors;
      if (!this.$v.varPFisicaLocal.cpf.required) {
        errors.push("CPF é obrigatório.");
      }
      if (!this.$v.varPFisicaLocal.cpf.validCPF) {
        errors.push("CPF inválido.");
      }
      return errors;
    },
  },

  watch: {
    staPFisicaSelect(pfisicaNovo) {
      this.metSincronizar(pfisicaNovo);
    },
  },

  created() {
    this.metSincronizar(this.staPFisicaSelect);
    if (this.staPFisicaSelect) {
      this.tituloForm = "Editar Pessoa Física";
    } else {
      this.tituloForm = "Nova Pessoa Física";
    }
    this.actPaisesSelect();
  },

  methods: {
    ...mapActions([
      "actSelecionarPFisica",
      "actResetarPFisica",
      "actCriarPFisica",
      "actEditarPFisica",
      "actResetarErro",
      "actPaisesSelect",
    ]),

    async metObterDadosCep(cep) {
      if (cep) {
        try {
          var dadosCep = await axios.getObterDadosCep(cep);
          this.varPFisicaLocal.endereco =
            dadosCep.data.tipo_logradouro + " " + dadosCep.data.nome_logradouro;
          this.varPFisicaLocal.bairro = dadosCep.data.bairro;
          this.varPFisicaLocal.cidade = dadosCep.data.nome_localidade;
          this.varPFisicaLocal.uf = dadosCep.data.uf;
        } catch (err) {
          // console.log(err);
        }
      }
    },

    metMaskFone(fone) {
      if (fone == null) {
        return "";
      }
      if (fone.includes("(")) {
        return fone.length <= 13 ? "(##)####-####" : "(##)# ####-####";
      } else {
        return fone.length <= 10 ? "(##)####-####" : "(##)# ####-####";
      }
    },

    selecionarPFisicaEdicao(pfisica) {
      this.actSelecionarPFisica({ pfisica });
    },

    async salvarPFisica() {
      switch (this.staPFisicaSelect) {
        case undefined:
          this.varPFisicaLocal.criacao =
            this.$store.state.login.usuario.usuario;
          this.varPFisicaLocal.modificacao =
            this.$store.state.login.usuario.usuario;
          await this.actCriarPFisica({
            pfisica: this.varPFisicaLocal,
            licenca: this.$store.state.login.licenca,
          });

          if (this.$store.state.finPFisicas.staErro != undefined) {
            this.$root.snackbar.show({
              type: "danger",
              message: this.$store.state.finPFisicas.staErro,
            });
            this.actResetarErro();
          } else {
            this.tituloForm = "Editar Pessoa Física";
            this.$root.snackbar.show({
              type: "success",
              message: "Pessoa Física Criada!",
            });
          }
          break;
        default:
          this.varPFisicaLocal.modificacao =
            this.$store.state.login.usuario.usuario;
          this.varPFisicaLocal.criado = undefined;
          this.varPFisicaLocal.criacao = undefined;
          this.varPFisicaLocal.modificado = undefined;
          await this.actEditarPFisica({
            pfisica: this.varPFisicaLocal,
            licenca: this.$store.state.login.licenca,
          });

          if (this.$store.state.finPFisicas.staErro != undefined) {
            this.$root.snackbar.show({
              type: "danger",
              message: this.$store.state.finPFisicas.staErro,
            });
            this.actResetarErro();
          } else {
            this.$root.snackbar.show({
              type: "success",
              message: "Pessoa Física Salva!",
            });
          }
      }
      //this.resetar();
    },

    metSincronizar(pfisica) {
      this.varPFisicaLocal = Object.assign(
        {},
        pfisica || {
          id: null,
          endereco: null,
          bairro: null,
          cidade: null,
          uf: null,
        }
      );
    },
    submit() {
      // this.$v.$touch();
      this.salvarPFisica();
      // this.$router.back();
    },
    metClear() {
      //this.$v.$reset();
      // this.varPFisicaLocal.ultima_compra = this.staPFisicaSelect.ultima_compra;
      // this.varPFisicaLocal.email = this.staPFisicaSelect.email;
      // this.varPFisicaLocal.pfisica = this.staPFisicaSelect.pfisica;
      // this.varPFisicaLocal.senha = this.staPFisicaSelect.senha;
      // this.varPFisicaLocal.ativo = this.staPFisicaSelect.ativo;
    },
    metVoltar() {
      this.$router.back();
    },
  },
};
</script>
